import * as actionTypes from '../../types'

// Search producers
export const searchProducersAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.SEARCH_PRODUCERS,
  payload,
  actionSuccess,
  actionFailure,
})
export const searchProducersSuccessAction = (payload) => ({
  type: actionTypes.SEARCH_PRODUCERS_SUCCESS,
  payload,
})
export const searchProducersFailureAction = () => ({
  type: actionTypes.SEARCH_PRODUCERS_FAILURE,
})

// Get producers
export const getProducersAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_PRODUCERS,
  payload,
  actionSuccess,
  actionFailure,
})

export const getProducersSuccessAction = (payload) => ({
  type: actionTypes.GET_PRODUCERS_SUCCESS,
  payload,
})
export const getProducersFailureAction = () => ({
  type: actionTypes.GET_PRODUCERS_FAILURE,
})

// Get producer
export const getProducerAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_PRODUCER,
  payload,
  actionSuccess,
  actionFailure,
})
export const getProducerSuccessAction = (payload) => ({
  type: actionTypes.GET_PRODUCER_SUCCESS,
  payload,
})
export const getProducerFailureAction = () => ({
  type: actionTypes.GET_PRODUCER_FAILURE,
})

// Create producer
export const createProducerAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.CREATE_PRODUCER,
  payload,
  actionSuccess,
  actionFailure,
})
export const createProducerSuccessAction = (payload) => ({
  type: actionTypes.CREATE_PRODUCER_SUCCESS,
  payload,
})
export const createProducerFailureAction = () => ({
  type: actionTypes.CREATE_PRODUCER_FAILURE,
})

// Update producer
export const updateProducerAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.UPDATE_PRODUCER,
  payload,
  actionSuccess,
  actionFailure,
})
export const updateProducerSuccessAction = (payload) => ({
  type: actionTypes.UPDATE_PRODUCER_SUCCESS,
  payload,
})
export const updateProducerFailureAction = () => ({
  type: actionTypes.UPDATE_PRODUCER_FAILURE,
})

// Active producer
export const activeProducerAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.ACTIVE_PRODUCER,
  payload,
  actionSuccess,
  actionFailure,
})
export const activeProducerSuccessAction = (payload) => ({
  type: actionTypes.ACTIVE_PRODUCER_SUCCESS,
  payload,
})
export const activeProducerFailureAction = () => ({
  type: actionTypes.ACTIVE_PRODUCER_FAILURE,
})

// Whether producer created producer
export const setProducerCreatedProducerAction = (payload) => ({
  type: actionTypes.SET_PRODUCER_CREATED_PRODUCER,
  payload,
})

// Whether producer created product
export const setProducerCreatedProductAction = (payload) => ({
  type: actionTypes.SET_PRODUCER_CREATED_PRODUCT,
  payload,
})
