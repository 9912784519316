import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  loading: false,
  created: false,
  purchaseOrderList: {
    data: [],
    meta: {
      total_count: 0,
    },
  },
  purchaseOrder: null,
  error: null,
}

const PurchaseOrderReducer = (state = DEFAULT_STATE, { payload, type }) => {
  switch (type) {
    case actionTypes.GET_PURCHASE_ORDERS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseOrderList: {
          data: payload?.data?.purchase_orders,
          meta: payload?.meta,
        },
      }
    case actionTypes.GET_PURCHASE_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case actionTypes.GET_PURCHASE_ORDER:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseOrder: payload,
      }
    case actionTypes.GET_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case actionTypes.CREATE_PURCHASE_ORDER:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CREATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        created: true,
      }
    case actionTypes.CREATE_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case actionTypes.UPDATE_PURCHASE_ORDER:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case actionTypes.CLEAR_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrder: null,
        created: false,
      }
    case actionTypes.UPDATE_STATUS_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrderList: {
          ...state.purchaseOrderList,
          data: state?.purchaseOrderList?.data?.map((i) => {
            if (payload?.id === i?.id) {
              return {
                ...i,
                status: payload?.status,
              }
            }

            return i
          }),
        },
      }
    default:
      return state
  }
}

export default PurchaseOrderReducer
