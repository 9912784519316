import * as actionTypes from '../../types'

// Get product category
export const getProductCategoryAction = (params, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_PRODUCT_CATEGORY,
  params,
  actionSuccess,
  actionFailure,
})
export const getProductCategorySuccessAction = (payload) => ({
  type: actionTypes.GET_PRODUCT_CATEGORY_SUCCESS,
  payload,
})
export const getProductCategoryFailureAction = () => ({
  type: actionTypes.GET_PRODUCT_CATEGORY_FAILURE,
})
