import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  loading: false,
  updating: null,
  clientMessageList: {
    data: [],
    meta: {
      total_count: 0,
    },
  },
}

const ClientMessageReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_CLIENT_MESSAGES:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_CLIENT_MESSAGES_SUCCESS:
      return {
        ...state,
        clientMessageList: {
          data: action.payload?.data?.messages,
          meta: action.payload?.meta,
        },
        loading: false,
      }
    case actionTypes.GET_CLIENT_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_CLIENT_MESSAGE:
      return {
        ...state,
        updating: action.payload?.id,
      }
    case actionTypes.UPDATE_CLIENT_MESSAGE_SUCCESS:
      return {
        ...state,
        updating: null,
      }
    case actionTypes.UPDATE_CLIENT_MESSAGE_FAILURE:
      return {
        updating: null,
      }
    default:
      return state
  }
}

export default ClientMessageReducer
