// App
export const APP_INIT = 'APP_INIT'

// Platform
export const SET_PLATFORM = 'SET_PLATFORM'
// VietFish registerService
export const GET_SERVICES = 'GET_SERVICES'
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS'
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE'

export const GET_USER_REGISTER_SERVICES = 'GET_USER_REGISTER_SERVICES'
export const GET_USER_REGISTER_SERVICES_SUCCESS = 'GET_USER_REGISTER_SERVICES_SUCCESS'
export const GET_USER_REGISTER_SERVICES_FAILURE = 'GET_USER_REGISTER_SERVICES_FAILURE'

export const UPDATE_USER_REGISTER_SERVICE = 'UPDATE_USER_REGISTER_SERVICE'
export const UPDATE_USER_REGISTER_SERVICE_SUCCESS = 'UPDATE_USER_REGISTER_SERVICE_SUCCESS'
export const UPDATE_USER_REGISTER_SERVICE_FAILURE = 'UPDATE_USER_REGISTER_SERVICE_FAILURE'

// User
export const SAVE_ACCOUNT_INFORMATION = 'SAVE_ACCOUNT_INFORMATION'
export const SAVE_ACCOUNT_INFORMATION_SUCCESS = 'SAVE_ACCOUNT_INFORMATION_SUCCESS'
export const SAVE_ACCOUNT_INFORMATION_FAILURE = 'SAVE_ACCOUNT_INFORMATION_FAILURE'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

// Authentication
export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const REGISTER_DEPARTMENT = 'REGISTER_DEPARTMENT'
export const REGISTER_DEPARTMENT_SUCCESS = 'REGISTER_DEPARTMENT_SUCCESS'
export const REGISTER_DEPARTMENT_FAILURE = 'REGISTER_DEPARTMENT_FAILURE'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const GET_WHO_AM_I = 'GET_WHO_AM_I'
export const GET_WHO_AM_I_SUCCESS = 'GET_WHO_AM_I_SUCCESS'
export const GET_WHO_AM_I_FAILURE = 'GET_WHO_AM_I_FAILURE'

export const SET_ACCOUNT = 'SET_ACCOUNT'

// Upload
export const UPLOAD = 'UPLOAD'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE'

// Producer
export const SEARCH_PRODUCERS = 'SEARCH_PRODUCERS'
export const SEARCH_PRODUCERS_SUCCESS = 'SEARCH_PRODUCERS_SUCCESS'
export const SEARCH_PRODUCERS_FAILURE = 'SEARCH_PRODUCERS_FAILURE'

export const GET_PRODUCERS = 'GET_PRODUCERS'
export const GET_PRODUCERS_SUCCESS = 'GET_PRODUCERS_SUCCESS'
export const GET_PRODUCERS_FAILURE = 'GET_PRODUCERS_FAILURE'

export const GET_PRODUCER = 'GET_PRODUCER'
export const GET_PRODUCER_SUCCESS = 'GET_PRODUCER_SUCCESS'
export const GET_PRODUCER_FAILURE = 'GET_PRODUCER_FAILURE'

export const CREATE_PRODUCER = 'CREATE_PRODUCER'
export const CREATE_PRODUCER_SUCCESS = 'CREATE_PRODUCER_SUCCESS'
export const CREATE_PRODUCER_FAILURE = 'CREATE_PRODUCER_FAILURE'

export const UPDATE_PRODUCER = 'UPDATE_PRODUCER'
export const UPDATE_PRODUCER_SUCCESS = 'UPDATE_PRODUCER_SUCCESS'
export const UPDATE_PRODUCER_FAILURE = 'UPDATE_PRODUCER_FAILURE'

export const ACTIVE_PRODUCER = 'ACTIVE_PRODUCER'
export const ACTIVE_PRODUCER_SUCCESS = 'ACTIVE_PRODUCER_SUCCESS'
export const ACTIVE_PRODUCER_FAILURE = 'ACTIVE_PRODUCER_FAILURE'

export const SET_PRODUCER_CREATED_PRODUCT = 'SET_PRODUCER_CREATED_PRODUCT'
export const SET_PRODUCER_CREATED_PRODUCER = 'SET_PRODUCER_CREATED_PRODUCER'

// Product
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS'
export const SEARCH_PRODUCTS_FAILURE = 'SEARCH_PRODUCTS_FAILURE'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE'

export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE'

export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE'

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE'

export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE'

export const CLEAR_PRODUCT = 'CLEAR_PRODUCT'

export const CREATE_PRODUCT_BATCH = 'CREATE_PRODUCT_BATCH'
export const CREATE_PRODUCT_BATCH_SUCCESS = 'CREATE_PRODUCT_BATCH_SUCCESS'
export const CREATE_PRODUCT_BATCH_FAILURE = 'CREATE_PRODUCT_BATCH_FAILURE'

export const UPDATE_PRODUCT_BATCH = 'UPDATE_PRODUCT_BATCH'
export const UPDATE_PRODUCT_BATCH_SUCCESS = 'UPDATE_PRODUCT_BATCH_SUCCESS'
export const UPDATE_PRODUCT_BATCH_FAILURE = 'UPDATE_PRODUCT_BATCH_FAILURE'

export const GET_PRODUCT_BATCHES = 'GET_PRODUCT_BATCHES'
export const GET_PRODUCT_BATCHES_SUCCESS = 'GET_PRODUCT_BATCHES_SUCCESS'
export const GET_PRODUCT_BATCHES_FAILURE = 'GET_PRODUCT_BATCHES_FAILURE'

export const GET_PRODUCT_BATCH = 'GET_PRODUCT_BATCH'
export const GET_PRODUCT_BATCH_SUCCESS = 'GET_PRODUCT_BATCH_SUCCESS'
export const GET_PRODUCT_BATCH_FAILURE = 'GET_PRODUCT_BATCH_FAILURE'

export const SELECT_PRODUCT_TO_ADD_BATCH = 'SELECT_PRODUCT_TO_ADD_BATCH'

// Layout
export const TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE'
export const SELECT_SUBMENU = 'SELECT_SUBMENU'
export const SET_ACCOUNT_SETUP_STEP = 'SET_ACCOUNT_SETUP_STEP'
export const SET_ADD_PRODUCT_STEP = 'SET_ADD_PRODUCT_STEP'
export const SET_LOADED_NEW_ACCOUNT_INTRO = 'SET_LOADED_NEW_ACCOUNT_INTRO'

// Product Type
export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES'
export const GET_PRODUCT_TYPES_SUCCESS = 'GET_PRODUCT_TYPES_SUCCESS'
export const GET_PRODUCT_TYPES_FAILURE = 'GET_PRODUCT_TYPES_FAILURE'

// Product Category
export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY'
export const GET_PRODUCT_CATEGORY_SUCCESS = 'GET_PRODUCT_CATEGORY_SUCCESS'
export const GET_PRODUCT_CATEGORY_FAILURE = 'GET_PRODUCT_CATEGORY_FAILURE'

// Sales
export const GET_SALES_CHANNELS = 'GET_SALES_CHANNELS'
export const GET_SALES_CHANNELS_SUCCESS = 'GET_SALES_CHANNELS_SUCCESS'
export const GET_SALES_CHANNELS_FAILURE = 'GET_SALES_CHANNELS_FAILURE'

export const REGISTER_SALES_CHANNEL = 'REGISTER_SALES_CHANNEL'
export const REGISTER_SALES_CHANNEL_SUCCESS = 'REGISTER_SALES_CHANNEL_SUCCESS'
export const REGISTER_SALES_CHANNEL_FAILURE = 'REGISTER_SALES_CHANNEL_FAILURE'

// Posts
export const RESET_POSTS = 'RESET_POSTS'

export const GET_POST_CATEGORIES = 'GET_POST_CATEGORIES'
export const GET_POST_CATEGORIES_SUCCESS = 'GET_POST_CATEGORIES_SUCCESS'
export const GET_POST_CATEGORIES_FAILURE = 'GET_POST_CATEGORIES_FAILURE'

export const GET_POSTS = 'GET_POSTS'
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS'
export const GET_POSTS_FAILURE = 'GET_POSTS_FAILURE'

export const GET_POST = 'GET_POST'
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS'
export const GET_POST_FAILURE = 'GET_POST_FAILURE'

// Admin
export const ADMIN_GET_POST = 'ADMIN_GET_POST'
export const ADMIN_GET_POST_SUCCESS = 'ADMIN_GET_POST_SUCCESS'
export const ADMIN_GET_POST_FAILURE = 'ADMIN_GET_POST_FAILURE'

export const ADMIN_GET_POSTS = 'ADMIN_GET_POSTS'
export const ADMIN_GET_POSTS_SUCCESS = 'ADMIN_GET_POSTS_SUCCESS'
export const ADMIN_GET_POSTS_FAILURE = 'ADMIN_GET_POSTS_FAILURE'

export const ADMIN_CREATE_POST = 'ADMIN_CREATE_POST'
export const ADMIN_CREATE_POST_SUCCESS = 'ADMIN_CREATE_POST_SUCCESS'
export const ADMIN_CREATE_POST_FAILURE = 'ADMIN_CREATE_POST_FAILURE'

export const ADMIN_UPDATE_POST = 'ADMIN_UPDATE_POST'
export const ADMIN_UPDATE_POST_SUCCESS = 'ADMIN_UPDATE_POST_SUCCESS'
export const ADMIN_UPDATE_POST_FAILURE = 'ADMIN_UPDATE_POST_FAILURE'

// User
export const UPDATE_REQUIRE_CHANGE_PASSWORD = 'UPDATE_REQUIRE_CHANGE_PASSWORD'

//OEM
export const SEARCH_OEM_PRODUCERS = 'SEARCH_OEM_PRODUCERS'
export const SEARCH_OEM_PRODUCERS_SUCCESS = 'SEARCH_OEM_PRODUCERS_SUCCESS'
export const SEARCH_OEM_PRODUCERS_FAILURE = 'SEARCH_OEM_PRODUCERS_FAILURE'

export const GET_OEM_PRODUCER = 'GET_OEM_PRODUCER'
export const GET_OEM_PRODUCER_SUCCESS = 'GET_OEM_PRODUCER_SUCCESS'
export const GET_OEM_PRODUCER_FAILURE = 'GET_OEM_PRODUCER_FAILURE'

export const GET_OEM_PRODUCERS = 'GET_OEM_PRODUCERS'
export const GET_OEM_PRODUCERS_SUCCESS = 'GET_OEM_PRODUCERS_SUCCESS'
export const GET_OEM_PRODUCERS_FAILURE = 'GET_OEM_PRODUCERS_FAILURE'

export const CREATE_OEM_PRODUCER = 'CREATE_OEM_PRODUCER'
export const CREATE_OEM_PRODUCER_SUCCESS = 'CREATE_OEM_PRODUCER_SUCCESS'
export const CREATE_OEM_PRODUCER_FAILURE = 'CREATE_OEM_PRODUCER_FAILURE'

export const UPDATE_OEM_PRODUCER = 'UPDATE_OEM_PRODUCER'
export const UPDATE_OEM_PRODUCER_SUCCESS = 'UPDATE_OEM_PRODUCER_SUCCESS'
export const UPDATE_OEM_PRODUCER_FAILURE = 'UPDATE_OEM_PRODUCER_FAILURE'

export const CLEAR_OEM_PRODUCER = 'CLEAR_OEM_PRODUCER'

export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS'
export const GET_PURCHASE_ORDERS_SUCCESS = 'GET_PURCHASE_ORDERS_SUCCESS'
export const GET_PURCHASE_ORDERS_FAILURE = 'GET_PURCHASE_ORDERS_FAILURE'
export const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER'
export const GET_PURCHASE_ORDER_SUCCESS = 'GET_PURCHASE_ORDER_SUCCESS'
export const GET_PURCHASE_ORDER_FAILURE = 'GET_PURCHASE_ORDER_FAILURE'
export const CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER'
export const CREATE_PURCHASE_ORDER_SUCCESS = 'CREATE_PURCHASE_ORDER_SUCCESS'
export const CREATE_PURCHASE_ORDER_FAILURE = 'CREATE_PURCHASE_ORDER_FAILURE'
export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER'
export const UPDATE_PURCHASE_ORDER_SUCCESS = 'UPDATE_PURCHASE_ORDER_SUCCESS'
export const UPDATE_PURCHASE_ORDER_FAILURE = 'UPDATE_PURCHASE_ORDER_FAILURE'
export const CLEAR_PURCHASE_ORDER = 'CLEAR_PURCHASE_ORDER'
export const UPDATE_STATUS_PURCHASE_ORDER = 'UPDATE_STATUS_PURCHASE_ORDER'

//client-messages
export const GET_CLIENT_MESSAGES = 'GET_CLIENT_MESSAGES'
export const GET_CLIENT_MESSAGES_SUCCESS = 'GET_CLIENT_MESSAGES_SUCCESS'
export const GET_CLIENT_MESSAGES_FAILURE = 'GET_CLIENT_MESSAGES_FAILURE'
export const UPDATE_CLIENT_MESSAGE = 'UPDATE_CLIENT_MESSAGE'
export const UPDATE_CLIENT_MESSAGE_SUCCESS = 'UPDATE_CLIENT_MESSAGE_SUCCESS'
export const UPDATE_CLIENT_MESSAGE_FAILURE = 'UPDATE_CLIENT_MESSAGE_FAILURE'
