import * as actionTypes from '../../types'

// Get sales channels
export const getSalesChannelsAction = (actionSuccess, actionFailure) => ({
  type: actionTypes.GET_SALES_CHANNELS,
  actionSuccess,
  actionFailure,
})
export const getSalesChannelsSuccessAction = (payload) => ({
  type: actionTypes.GET_SALES_CHANNELS_SUCCESS,
  payload,
})
export const getSalesChannelsFailureAction = () => ({
  type: actionTypes.GET_SALES_CHANNELS_FAILURE,
})

// Register sales channel
export const registerSalesChannelAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.REGISTER_SALES_CHANNEL,
  payload,
  actionSuccess,
  actionFailure,
})
export const registerSalesChannelSuccessAction = (payload) => ({
  type: actionTypes.REGISTER_SALES_CHANNEL_SUCCESS,
  payload,
})
export const registerSalesChannelFailureAction = () => ({
  type: actionTypes.REGISTER_SALES_CHANNEL_FAILURE,
})
