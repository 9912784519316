import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  isLoading: false,
  isGetting: false,
  isActiveProducer: false,
  data: null,
  createdProduct: null,
  isGettingProducers: false,
}

const ProducerReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.CREATE_OEM_PRODUCER:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.CREATE_OEM_PRODUCER_FAILURE:
      return { ...state, isLoading: false }
    case actionTypes.CREATE_OEM_PRODUCER_SUCCESS:
      return { ...state, data: { ...payload }, isLoading: false }
    case actionTypes.UPDATE_OEM_PRODUCER:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.UPDATE_OEM_PRODUCER_FAILURE:
      return { ...state, isLoading: false }
    case actionTypes.UPDATE_OEM_PRODUCER_SUCCESS:
      return { ...state, data: { ...payload }, isLoading: false }
    case actionTypes.GET_OEM_PRODUCER:
      return {
        ...state,
        isGetting: true,
      }
    case actionTypes.GET_OEM_PRODUCER_FAILURE:
      return { ...state, isGetting: false }
    case actionTypes.GET_OEM_PRODUCER_SUCCESS:
      return { ...state, data: { ...payload }, isGetting: false }
    case actionTypes.GET_OEM_PRODUCERS:
      return {
        ...state,
        isGettingProducers: true,
      }
    case actionTypes.GET_OEM_PRODUCERS_FAILURE:
      return { ...state, isGettingProducers: false }
    case actionTypes.GET_OEM_PRODUCERS_SUCCESS:
      return {
        ...state,
        dataProducers: { ...payload.data },
        meta: { ...payload.meta },
        isGettingProducers: false,
      }
    case actionTypes.SEARCH_OEM_PRODUCERS:
      return {
        ...state,
        isGetting: true,
      }
    case actionTypes.SEARCH_OEM_PRODUCERS_FAILURE:
      return { ...state, isGetting: false, error: payload }
    case actionTypes.SEARCH_OEM_PRODUCERS_SUCCESS:
      return {
        ...state,
        dataProducers: { ...payload.data },
        meta: { ...payload.meta },
        isGetting: false,
        error: null,
      }
    case actionTypes.CLEAR_OEM_PRODUCER:
      return {
        ...state,
        data: null,
      }
    default:
      return state
  }
}

export default ProducerReducer
