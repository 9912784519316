import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  loading: false,
}

const SignUpReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes.SIGN_UP:
    case actionTypes.REGISTER_DEPARTMENT:
      return { ...state, loading: true }
    case actionTypes.SIGN_UP_FAILURE:
      return { ...state, loading: false }
    case actionTypes.SIGN_UP_SUCCESS:
      return { ...state, loading: false }
    default:
      return state
  }
}

export default SignUpReducer
