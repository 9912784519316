import { call, put, takeLatest } from 'redux-saga/effects'
import {
  activeProducerFailureAction,
  activeProducerSuccessAction,
  createProducerFailureAction,
  createProducerSuccessAction,
  getProducerFailureAction,
  getProducersFailureAction,
  getProducersSuccessAction,
  getProducerSuccessAction,
  searchProducersFailureAction,
  searchProducersSuccessAction,
  updateProducerFailureAction,
  updateProducerSuccessAction,
} from '~/redux/actions/Producer'
import {
  createProducerAPI,
  getProducerAPI,
  getProducersAPI,
  searchProducersAPI,
  updateProducerAPI,
} from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.GET_PRODUCERS, getProducersSaga)
  yield takeLatest(actionTypes.GET_PRODUCER, getProducerSaga)
  yield takeLatest(actionTypes.CREATE_PRODUCER, createProducerSaga)
  yield takeLatest(actionTypes.UPDATE_PRODUCER, updateProducerSaga)
  yield takeLatest(actionTypes.SEARCH_PRODUCERS, searchProducersSaga)
  yield takeLatest(actionTypes.ACTIVE_PRODUCER, activeProducerSaga)
}

function* getProducersSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getProducersAPI, payload)
    yield put(getProducersSuccessAction({ data: response?.data, meta: response?.meta }))
    actionSuccess && actionSuccess(response?.data?.producers, response?.meta)
  } catch (error) {
    yield put(getProducersFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* getProducerSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getProducerAPI, payload)
    yield put(getProducerSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(getProducerFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* createProducerSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(createProducerAPI, payload)
    yield put(createProducerSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(createProducerFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* updateProducerSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(updateProducerAPI, payload)
    yield put(updateProducerSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(updateProducerFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* activeProducerSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(updateProducerAPI, payload)
    yield put(activeProducerSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(activeProducerFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* searchProducersSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(searchProducersAPI, payload)
    yield put(searchProducersSuccessAction({ data: response?.data, meta: response?.meta }))
    actionSuccess && actionSuccess(response?.data?.producers, response?.meta)
  } catch (error) {
    yield put(searchProducersFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}
