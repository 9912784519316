import * as actionTypes from '../../types'

export const getPurchaseOrdersAction = (payload) => ({
  type: actionTypes.GET_PURCHASE_ORDERS,
  payload,
})

export const getPurchaseOrdersSuccessAction = (payload) => ({
  type: actionTypes.GET_PURCHASE_ORDERS_SUCCESS,
  payload,
})

export const getPurchaseOrdersFailureAction = (payload) => ({
  type: actionTypes.GET_PURCHASE_ORDERS_FAILURE,
  payload,
})

export const getPurchaseOrderAction = (payload) => ({
  type: actionTypes.GET_PURCHASE_ORDER,
  payload,
})

export const getPurchaseOrderSuccessAction = (payload) => ({
  type: actionTypes.GET_PURCHASE_ORDER_SUCCESS,
  payload,
})

export const getPurchaseOrderFailureAction = (payload) => ({
  type: actionTypes.GET_PURCHASE_ORDER_FAILURE,
  payload,
})

export const createPurchaseOrderAction = (payload) => ({
  type: actionTypes.CREATE_PURCHASE_ORDER,
  payload,
})

export const createPurchaseOrderSuccessAction = (payload) => ({
  type: actionTypes.CREATE_PURCHASE_ORDER_SUCCESS,
  payload,
})

export const createPurchaseOrderFailureAction = (payload) => ({
  type: actionTypes.CREATE_PURCHASE_ORDER_FAILURE,
  payload,
})

export const updatePurchaseOrderAction = (payload) => ({
  type: actionTypes.UPDATE_PURCHASE_ORDER,
  payload,
})

export const updatePurchaseOrderSuccessAction = (payload) => ({
  type: actionTypes.UPDATE_PURCHASE_ORDER_SUCCESS,
  payload,
})

export const updatePurchaseOrderFailureAction = (payload) => ({
  type: actionTypes.UPDATE_PURCHASE_ORDER_FAILURE,
  payload,
})

export const clearPurchaseOrderAction = () => ({
  type: actionTypes.CLEAR_PURCHASE_ORDER,
})

export const updateStatusPurchaseOrderAction = (payload) => ({
  type: actionTypes.UPDATE_STATUS_PURCHASE_ORDER,
  payload,
})
