import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  isLoading: false,
  isGetting: false,
  isActiveProducer: false,
  data: null,
  createdProduct: null,
  isGettingProducers: false,
  isExported: false,
  error: null,
}

const ProducerReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.CREATE_PRODUCER:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.CREATE_PRODUCER_FAILURE:
      return { ...state, isLoading: false }
    case actionTypes.CREATE_PRODUCER_SUCCESS:
      return { ...state, data: { ...payload }, isLoading: false }
    case actionTypes.UPDATE_PRODUCER:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.UPDATE_PRODUCER_FAILURE:
      return { ...state, isLoading: false }
    case actionTypes.UPDATE_PRODUCER_SUCCESS:
      return { ...state, data: { ...payload }, isLoading: false }
    case actionTypes.ACTIVE_PRODUCER:
      return {
        ...state,
        isActiveProducer: true,
      }
    case actionTypes.ACTIVE_PRODUCER_FAILURE:
      return { ...state, isActiveProducer: false }
    case actionTypes.ACTIVE_PRODUCER_SUCCESS:
      return { ...state, isActiveProducer: false }
    case actionTypes.GET_PRODUCER:
      return {
        ...state,
        isGetting: true,
      }
    case actionTypes.GET_PRODUCER_FAILURE:
      return { ...state, isGetting: false }
    case actionTypes.GET_PRODUCER_SUCCESS:
      return { ...state, data: { ...payload }, isGetting: false }
    case actionTypes.GET_PRODUCERS:
      return {
        ...state,
        isGettingProducers: true,
      }
    case actionTypes.GET_PRODUCERS_FAILURE:
      return { ...state, isGettingProducers: false }
    case actionTypes.GET_PRODUCERS_SUCCESS:
      return {
        ...state,
        dataProducers: { ...payload.data },
        meta: { ...payload.meta },
        isGettingProducers: false,
      }
    case actionTypes.SET_PRODUCER_CREATED_PRODUCT:
      return {
        ...state,
        createdProduct: payload,
      }
    case actionTypes.SEARCH_PRODUCERS:
      return {
        ...state,
        isGetting: true,
      }
    case actionTypes.SEARCH_PRODUCERS_FAILURE:
      return { ...state, isGetting: false, error: payload }
    case actionTypes.SEARCH_PRODUCERS_SUCCESS:
      return {
        ...state,
        dataProducers: { ...payload.data },
        meta: { ...payload.meta },
        isGetting: false,
        error: null,
      }
    case actionTypes.LOGOUT:
      return {
        ...DEFAULT_STATE,
      }
    default:
      return state
  }
}

export default ProducerReducer
