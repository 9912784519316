import { Button, Col, Form, Input, Modal, Row } from 'antd'
import { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from '~/i18n'
import { updateRequireChangePasswordAction } from '~/redux/actions/User'
import { changePasswordAPI } from '~/redux/apis'

function RequireChangePasswordModal({ t, updateRequireChangePassword }) {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [error, setError] = useState(null)
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }
  const changePassword = (values) => {
    let valid = true
    setLoading(true)
    setStatus(null)
    setError(null)
    if (values.password != values.confirmPassword) {
      valid = false
      setError(t('passwordNotMatches'))
    }
    if (valid) {
      changePasswordAPI({
        old_password: values.old_password,
        password: values.password,
      })
        .then(() => {
          setStatus(true)
          updateRequireChangePassword()
        })
        .catch(() => {
          setStatus(false)
          setLoading(false)
        })
    }
  }
  return (
    <Modal
      visible={status == null || status == false}
      title={t('changePassword')}
      closable={false}
      closeIcon={null}
      footer={
        <Row justify="center">
          <Col span={24}>
            <Row justify="center">
              {error && <span className="error-message">{error}</span>}
              {status != null ? (
                status ? (
                  <span className="success-message">{t('changePasswordSuccess')}</span>
                ) : (
                  <span className="error-message">{t('changePasswordFailure')}</span>
                )
              ) : (
                <></>
              )}
            </Row>
          </Col>
          <Col>
            <Button loading={loading} htmlType="submit" form="changePasswordForm" type="primary">
              {t('ok')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Form id="changePasswordForm" onFinish={changePassword} {...formLayout}>
        <Form.Item
          label={t('currentPassword')}
          name="old_password"
          rules={[{ required: true, message: t('pleaseInputCurrentPassword') }]}
        >
          <Input
            type="password"
            minLength={6}
            maxLength={18}
            placeholder={t('inputCurrentPassword')}
          />
        </Form.Item>
        <Form.Item
          label={t('password')}
          name="password"
          rules={[{ required: true, message: t('pleaseInputPassword') }]}
        >
          <Input type="password" minLength={6} maxLength={18} placeholder={t('inputPassword')} />
        </Form.Item>
        <Form.Item
          label={t('confirmPassword')}
          name="confirmPassword"
          rules={[{ required: true, message: t('pleaseInputConfirmPassword') }]}
        >
          <Input
            type="password"
            minLength={6}
            maxLength={18}
            placeholder={t('inputConfirmPassword')}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateRequireChangePassword: () => dispatch(updateRequireChangePasswordAction()),
})

export default compose(
  withTranslation('requireChangePasswordModal'),
  connect(null, mapDispatchToProps),
)(RequireChangePasswordModal)
