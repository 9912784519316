import * as actionTypes from '../../types'

export const getServicesAction = (payload) => ({
  type: actionTypes.GET_SERVICES,
  payload,
})

export const getServicesSuccessAction = (payload) => ({
  type: actionTypes.GET_SERVICES_SUCCESS,
  payload,
})

export const getServicesFailureAction = (payload) => ({
  type: actionTypes.GET_SERVICES_FAILURE,
  payload,
})

export const getUserRegisterServicesAction = (payload) => ({
  type: actionTypes.GET_USER_REGISTER_SERVICES,
  payload,
})

export const getUserRegisterServicesSuccessAction = (payload) => ({
  type: actionTypes.GET_USER_REGISTER_SERVICES_SUCCESS,
  payload,
})

export const getUserRegisterServicesFailureAction = (payload) => ({
  type: actionTypes.GET_USER_REGISTER_SERVICES_FAILURE,
  payload,
})

export const updateUserRegisterServiceAction = (payload) => ({
  type: actionTypes.UPDATE_USER_REGISTER_SERVICE,
  payload,
})

export const updateUserRegisterServiceSuccessAction = (payload) => ({
  type: actionTypes.UPDATE_USER_REGISTER_SERVICE_SUCCESS,
  payload,
})

export const updateUserRegisterServiceFailureAction = (payload) => ({
  type: actionTypes.UPDATE_USER_REGISTER_SERVICE_FAILURE,
  payload,
})
