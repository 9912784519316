import { combineReducers } from 'redux'
import * as actionTypes from '../types'
import clientMessages from './ClientMessages'
import layout from './Layout'
import login from './Login'
import OEMProducer from './OEMProducer'
import posts from './Posts'
import producer from './Producer'
import productCategory from './ProductCategory'
import products from './Products'
import productTypes from './ProductTypes'
import purchaseOrders from './PurchaseOrders'
import sales from './Sales'
import servicesApproval from './ServicesApproval'
import signup from './SignUp'
import user from './User'

const appReducer = combineReducers({
  login,
  signup,
  user,
  producer,
  products,
  productTypes,
  layout,
  productCategory,
  sales,
  posts,
  OEMProducer,
  purchaseOrders,
  servicesApproval,
  clientMessages,
})

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT) {
    localStorage.removeItem('persist:root')
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
