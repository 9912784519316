import * as actionTypes from '../../types'

export const getClientMessagesAction = () => ({
  type: actionTypes.GET_CLIENT_MESSAGES,
})

export const getClientMessagesSuccessAction = (payload) => ({
  type: actionTypes.GET_CLIENT_MESSAGES_SUCCESS,
  payload,
})

export const getClientMessagesFailureAction = (payload) => ({
  type: actionTypes.GET_CLIENT_MESSAGES_FAILURE,
  payload,
})

export const updateClientMessageAction = (payload) => ({
  type: actionTypes.UPDATE_CLIENT_MESSAGE,
  payload,
})

export const updateClientMessageSuccessAction = (payload) => ({
  type: actionTypes.UPDATE_CLIENT_MESSAGE_SUCCESS,
  payload,
})

export const updateClientMessageFailureAction = (payload) => ({
  type: actionTypes.UPDATE_CLIENT_MESSAGE_FAILURE,
  payload,
})
