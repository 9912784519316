/* eslint-disable react/display-name */
import DefaultLayout from '~/components/DefaultLayout'
import RenderHeadTitle from '~/components/RenderHeadTitle'
import { withTranslation } from '~/i18n'
// import './styles.scss'

const withPageContent =
  ({ titleKey, translation, extra = null }) =>
  (ComposedComponent) =>
    withTranslation(translation)(function PageContent({ t }) {
      return (
        <DefaultLayout>
          <RenderHeadTitle headTitle={t('headTitle')} headTitleVF={t('headTitleVF')} />
          <div className="admin-account-setup">
            <div className="topbar">
              <div className="page-title">
                {t(titleKey || 'pageTitle')}
                <div className="line" />
              </div>
              {extra}
            </div>
            <div className="page-content">
              <div className="right-wrapper">
                <div className="right">
                  <ComposedComponent />
                </div>
              </div>
            </div>
          </div>
        </DefaultLayout>
      )
    })

export default withPageContent
