import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getServicesFailureAction,
  getServicesSuccessAction,
  getUserRegisterServicesFailureAction,
  getUserRegisterServicesSuccessAction,
  updateUserRegisterServiceFailureAction,
  updateUserRegisterServiceSuccessAction,
} from '~/redux/actions/ServicesApproval'
import {
  getServicesAPI,
  getUserRegisterServicesAPI,
  updateUserRegisterServicesAPI,
} from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.GET_SERVICES, getServicesSaga)
  yield takeLatest(actionTypes.GET_USER_REGISTER_SERVICES, getUserRegisterServicesSaga)
  yield takeLatest(actionTypes.UPDATE_USER_REGISTER_SERVICE, updateUserRegisterServicesSaga)
}

function* getServicesSaga({ payload }) {
  try {
    const response = yield call(getServicesAPI, payload)
    yield put(getServicesSuccessAction(response))
  } catch (error) {
    yield put(getServicesFailureAction(error))
    console.log(error)
  }
}

function* getUserRegisterServicesSaga({ payload }) {
  try {
    const response = yield call(getUserRegisterServicesAPI, payload)
    yield put(getUserRegisterServicesSuccessAction(response))
  } catch (error) {
    yield put(getUserRegisterServicesFailureAction(error))
    console.log(error)
  }
}

function* updateUserRegisterServicesSaga({ payload }) {
  try {
    const response = yield call(updateUserRegisterServicesAPI, payload)
    yield put(updateUserRegisterServiceSuccessAction(response?.data))
  } catch (error) {
    yield put(updateUserRegisterServiceFailureAction(error))
    console.log(error)
  }
}
