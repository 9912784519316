import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createOemProducerFailureAction,
  createOemProducerSuccessAction,
  getOemProducerFailureAction,
  getOemProducersFailureAction,
  getOemProducersSuccessAction,
  getOemProducerSuccessAction,
  searchOemProducersFailureAction,
  searchOemProducersSuccessAction,
  updateOemProducerFailureAction,
  updateOemProducerSuccessAction,
} from '~/redux/actions/OEMProducer'
import {
  createOemProducerAPI,
  getOemProducerAPI,
  getOemProducersAPI,
  searchOemProducersAPI,
  updateOemProducerAPI,
} from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.GET_OEM_PRODUCERS, getProducersSaga)
  yield takeLatest(actionTypes.GET_OEM_PRODUCER, getProducerSaga)
  yield takeLatest(actionTypes.CREATE_OEM_PRODUCER, createProducerSaga)
  yield takeLatest(actionTypes.UPDATE_OEM_PRODUCER, updateProducerSaga)
  yield takeLatest(actionTypes.SEARCH_OEM_PRODUCERS, searchProducersSaga)
}

function* getProducersSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getOemProducersAPI, payload)
    yield put(getOemProducersSuccessAction({ data: response?.data, meta: response?.meta }))
    actionSuccess && actionSuccess(response?.data?.producers, response?.meta)
  } catch (error) {
    yield put(getOemProducersFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* getProducerSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getOemProducerAPI, payload)
    yield put(getOemProducerSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(getOemProducerFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* createProducerSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(createOemProducerAPI, payload)
    yield put(createOemProducerSuccessAction(response?.data))
    actionSuccess && actionSuccess(response?.data)
  } catch (error) {
    yield put(createOemProducerFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* updateProducerSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(updateOemProducerAPI, payload)
    yield put(updateOemProducerSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(updateOemProducerFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* searchProducersSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(searchOemProducersAPI, payload)
    yield put(searchOemProducersSuccessAction({ data: response?.data, meta: response?.meta }))
    actionSuccess && actionSuccess(response?.data?.producers, response?.meta)
  } catch (error) {
    yield put(searchOemProducersFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}
