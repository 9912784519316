import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createProductBatchFailureAction,
  createProductBatchSuccessAction,
  getProductBatchesFailureAction,
  getProductBatchesSuccessAction,
  getProductBatchFailureAction,
  getProductBatchSuccessAction,
  updateProductBatchFailureAction,
  updateProductBatchSuccessAction,
} from '~/redux/actions/ProductBatch'
import {
  createProductBatchAPI,
  getProductBatchAPI,
  getProductBatchesAPI,
  updateProductBatchAPI,
} from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.CREATE_PRODUCT_BATCH, createProductBatchSaga)
  yield takeLatest(actionTypes.GET_PRODUCT_BATCHES, getProductBatchesSaga)
  yield takeLatest(actionTypes.GET_PRODUCT_BATCH, getProductBatchSaga)
  yield takeLatest(actionTypes.UPDATE_PRODUCT_BATCH, updateProductBatchSaga)
}

function* getProductBatchSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getProductBatchAPI, payload)
    yield put(getProductBatchSuccessAction())
    actionSuccess && actionSuccess(response?.data)
  } catch (error) {
    yield put(getProductBatchFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* getProductBatchesSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getProductBatchesAPI, payload)
    yield put(
      getProductBatchesSuccessAction({
        data: response?.data?.traceabilities,
        meta: response?.meta,
      }),
    )
    actionSuccess && actionSuccess({ data: response?.data?.traceabilities, meta: response?.meta })
  } catch (error) {
    yield put(getProductBatchesFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* createProductBatchSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(createProductBatchAPI, payload)
    yield put(createProductBatchSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(createProductBatchFailureAction(error.response))
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* updateProductBatchSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(updateProductBatchAPI, payload)
    yield put(updateProductBatchSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(updateProductBatchFailureAction(error.response))
    actionFailure && actionFailure()
    console.log(error)
  }
}
