import { call, put, takeEvery } from 'redux-saga/effects'
import { uploadFailureAction, uploadSuccessAction } from '~/redux/actions/Upload'
import { uploadImageAPI } from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeEvery(actionTypes.UPLOAD, uploadImageSaga)
}

function* uploadImageSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(uploadImageAPI, payload)
    yield put(uploadSuccessAction())
    actionSuccess && actionSuccess({ ...response.data })
  } catch (error) {
    yield put(uploadFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}
