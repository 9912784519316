import * as actionTypes from '../../types'

// Search producers
export const searchOemProducersAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.SEARCH_OEM_PRODUCERS,
  payload,
  actionSuccess,
  actionFailure,
})
export const searchOemProducersSuccessAction = (payload) => ({
  type: actionTypes.SEARCH_OEM_PRODUCERS_SUCCESS,
  payload,
})
export const searchOemProducersFailureAction = () => ({
  type: actionTypes.SEARCH_OEM_PRODUCERS_FAILURE,
})

// Get producers
export const getOemProducersAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_OEM_PRODUCERS,
  payload,
  actionSuccess,
  actionFailure,
})

export const getOemProducersSuccessAction = (payload) => ({
  type: actionTypes.GET_OEM_PRODUCERS_SUCCESS,
  payload,
})
export const getOemProducersFailureAction = () => ({
  type: actionTypes.GET_OEM_PRODUCERS_FAILURE,
})

// Get producer
export const getOemProducerAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_OEM_PRODUCER,
  payload,
  actionSuccess,
  actionFailure,
})
export const getOemProducerSuccessAction = (payload) => ({
  type: actionTypes.GET_OEM_PRODUCER_SUCCESS,
  payload,
})
export const getOemProducerFailureAction = () => ({
  type: actionTypes.GET_OEM_PRODUCER_FAILURE,
})

// Create producer
export const createOemProducerAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.CREATE_OEM_PRODUCER,
  payload,
  actionSuccess,
  actionFailure,
})
export const createOemProducerSuccessAction = (payload) => ({
  type: actionTypes.CREATE_OEM_PRODUCER_SUCCESS,
  payload,
})
export const createOemProducerFailureAction = () => ({
  type: actionTypes.CREATE_OEM_PRODUCER_FAILURE,
})

// Update producer
export const updateOemProducerAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.UPDATE_OEM_PRODUCER,
  payload,
  actionSuccess,
  actionFailure,
})
export const updateOemProducerSuccessAction = (payload) => ({
  type: actionTypes.UPDATE_OEM_PRODUCER_SUCCESS,
  payload,
})
export const updateOemProducerFailureAction = () => ({
  type: actionTypes.UPDATE_OEM_PRODUCER_FAILURE,
})

export const clearOemProducerAction = () => ({
  type: actionTypes.CLEAR_OEM_PRODUCER,
})
