import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createProductFailureAction,
  createProductSuccessAction,
  deleteProductFailureAction,
  deleteProductSuccessAction,
  getProductFailureAction,
  getProductsFailureAction,
  getProductsSuccessAction,
  getProductSuccessAction,
  searchProductsFailureAction,
  searchProductsSuccessAction,
  updateProductFailureAction,
  updateProductSuccessAction,
} from '~/redux/actions/Product'
import {
  createProductAPI,
  deleteProductAPI,
  getProductAPI,
  getProductsAPI,
  searchProductsAPI,
  updateProductAPI,
} from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.CREATE_PRODUCT, createProductSaga)
  yield takeLatest(actionTypes.GET_PRODUCTS, getProductsSaga)
  yield takeLatest(actionTypes.SEARCH_PRODUCTS, searchProductsSaga)
  yield takeLatest(actionTypes.GET_PRODUCT, getProductSaga)
  yield takeLatest(actionTypes.UPDATE_PRODUCT, updateProductSaga)
  yield takeLatest(actionTypes.DELETE_PRODUCT, deleteProductSaga)
}

function* getProductSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getProductAPI, payload)
    yield put(getProductSuccessAction(response?.data))
    actionSuccess && actionSuccess(response?.data)
  } catch (error) {
    yield put(getProductFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* getProductsSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getProductsAPI, payload)
    yield put(getProductsSuccessAction({ data: response?.data, meta: response?.meta }))
    actionSuccess && actionSuccess({ data: response?.data })
  } catch (error) {
    yield put(getProductsFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* searchProductsSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(searchProductsAPI, payload)
    yield put(searchProductsSuccessAction({ data: response?.data, meta: response?.meta }))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(searchProductsFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* createProductSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(createProductAPI, payload)
    yield put(createProductSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(createProductFailureAction(error.response))
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* updateProductSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(updateProductAPI, payload)
    yield put(updateProductSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(updateProductFailureAction(error.response))
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* deleteProductSaga({ payload, actionSuccess, actionFailure }) {
  try {
    yield call(deleteProductAPI, payload)
    yield put(deleteProductSuccessAction())
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(deleteProductFailureAction(error.response))
    actionFailure && actionFailure()
    console.log(error)
  }
}
