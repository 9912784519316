export const PHONE_PATTERN = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
export const BUSINESS_TYPE = ['enterprise', 'cooperative', 'production_base', 'household']
export const CAREER_TYPE = ['seafood', 'breed', 'cultivate', 'agricultural_production']
export const CALCULATION_UNITS = [
  'Cái',
  'Bộ',
  'Túi',
  'Kg',
  'Chiếc',
  'Đôi',
  'Hộp',
  'Chai',
  'Thùng',
  'Khay',
]
export const ORDER_FEES = ['shipping_price', 'discount_price']
export const VAT_OPTIONS = [0, 5, 8, 10]
export const PRODUCER_STATUS = [
  'pending',
  'no_contact_yet',
  'made_call',
  'made_appointment',
  'sample_sent',
  'product_arrived',
  'done',
]
export const PURCHASE_ORDER_STATUSES = ['pending', 'approved', 'closed']
export const AGRICULTURAL_CERTIFICATES = [
  'BRC',
  'EU organic',
  'FDA',
  'Fairtrade',
  'GlobalGap',
  'HACCP',
  'HALAL (JAKIM)',
  'HALAL (UAE)',
  'HVNCLC - Bình chọn',
  'HVNCLC - Hội nhập',
  'ISO',
  'JAS',
  'KOSHER',
  'LocalGap',
  'Rainforest',
  'USDA',
  'VietGap',
  'Vệ sinh ATTP',
]
export const VIETFISH_PRODUCT_CERTIFICATES = [
  'ASC',
  'BAP',
  'BRC',
  'BSCI',
  'EUcode',
  'FDAcode',
  'FOS',
  'GGN',
  'Global Gap',
  'HACCP',
  'HALAL',
  'IFS',
  'ISO',
  'KOSHER',
  'MSC',
  'Naturland',
  'SA8000',
  'SMETA',
]

export const VIETNAM_CITIES = [
  'Hồ Chí Minh',
  'Hà Nội',
  'Thái Nguyên',
  'Hải Phòng',
  'Bắc Giang',
  'Vĩnh Phúc',
  'Thanh Hoá',
  'Hà Tĩnh',
  'Quảng Nam',
  'Phú Yên',
  'Khánh Hoà',
  'Gia Lai',
  'Tây Ninh',
  'Đồng Nai',
  'Long An',
  'An Giang',
  'Tiền Giang',
  'Bến Tre',
  'Vĩnh Long',
  'Trà Vinh',
  'Cần Thơ',
  'Sóc Trăng',
  'Kiên Giang',
  'Bạc Liêu',
  'Cà Mau',
  'Quảng Bình',
  'Bắc Ninh',
  'Bình Dương',
  'Bình Định',
  'Đà Nẵng',
  'Đồng Tháp',
  'Hưng Yên',
  'Kon Tum',
  'Nam Định',
  'Ninh Bình',
  'Phú Thọ',
  'Quảng Ninh',
  'Thái Bình',
  'Đắk Lắk',
  'Hậu Giang',
  'Hải Dương',
  'Lạng Sơn',
  'Lào Cai',
  'Yên Bái',
  'Tuyên Quang',
  'Hà Giang',
  'Cao Bằng',
  'Điện Biên',
  'Sơn La',
  'Hòa Bình',
  'Lai Châu',
  'Hà Nam',
  'Nghệ An',
  'Ninh Thuận',
  'Bình Thuận',
  'Lâm Đồng',
  'Đắk Nông',
  'Quảng Ngãi',
  'Quảng Trị',
  'Thừa Thiên - Huế',
  'Bà Rịa - Vũng Tàu',
  'Bắc Kạn',
  'Bình Phước',
]

export const COUNTRIES = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'AndorrA',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  "Cote D'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and Mcdonald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran, Islamic Republic Of',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'North Korea',
  'South Korea',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia, The Former Yugoslav Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory, Occupied',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'RWANDA',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia and Montenegro',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan, Province of China',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands, British',
  'Virgin Islands, U.S.',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

export const DTRACK_RULES = ['read', 'create', 'update']
export const DTRACK_USER_ROLES = [
  {
    role: 'admin',
    hasRules: false,
  },
  {
    role: 'customer_support',
    hasRules: false,
  },
  {
    role: 'purchasing',
    hasRules: false,
  },
  {
    role: 'approve_purchase_order',
    hasRules: false,
  },
  {
    role: 'approver',
    hasRules: false,
  },
  {
    role: 'user',
    hasRules: true,
  },
  {
    role: 'purchase_order',
    hasRules: true,
  },
  {
    role: 'producer_oem',
    hasRules: true,
  },
  {
    role: 'post',
    hasRules: true,
  },
  {
    role: 'sale_channel_reference',
    hasRules: true,
  },
  {
    role: 'product',
    hasRules: true,
  },
  {
    role: 'producer',
    hasRules: true,
  },
  {
    role: 'user_register_service',
    hasRules: true,
  },
  {
    role: 'message',
    hasRules: true,
  },
]
export const VIETFISH_USER_ROLES = ['admin']

// Platforms
export const VIETFISH = 'VietFish'
export const DTRACK = 'DTrack'
export const FOODMAP = 'Foodmap'
export const PLATFORM_DTRACK = { platform: DTRACK, primaryColor: '#029147' }
export const PLATFORM_VIETFISH = { platform: VIETFISH, primaryColor: '#145da0' }
export const PLATFORM_FOODMAP = { platform: FOODMAP, primaryColor: '#029147' }
// Hostnames
export const DTRACK_HEADER = 'dtrack.asia'
export const VIETFISH_HEADER = 'vietfish.com.vn'
export const FOODMAP_HEADER = 'foodmap.asia'
export const DTRACK_HOST = 'dtrack.asia'
export const DTRACK_WWW_HOST = 'www.dtrack.asia'
export const DTRACK_VN_HOST = 'dtrack.vn'
export const STAGE_DTRACK_HOST = 'stage-admin.dtrack.asia'
export const VIETFISH_HOST = 'nhacung.vietfish.com.vn'
export const STAGE_DTRACK_HOST_V2 = 'stage-admin-v2.dtrack.asia'
export const STAGE_VIETFISH_HOST = 'nhacung-stage.efarm.asia'
export const VIETFISH_ONLINE_HOST = 'nhacung.vietfish.online'
export const STAGE_VIETFISH_ONLINE_HOST = 'nhacung-stage.vietfish.online'
export const VIETFISH_COM_VN_HOST = 'nhacung.vietfish.com.vn'
export const LOCAL_HOST = 'localhost'
export const LOCAL_HOST_P3000 = 'localhost:3000'
export const LOCAL_HOST_P3001 = 'localhost:3001'
export const LOCAL_HOST_P3002 = 'localhost:3002'
export const NGROK_HOST = '4cbeae6fb0b2.ngrok.io'
export const LOCAL_HOST_P3009 = 'localhost:3009'
export const STAGE_STOREFRONT_HOST = 'stage.vietfish.com.vn'
export const STOREFRONT_HOST = 'vietfish.com.vn'
export const STAGE_FOODMAP_HOST = 'foodmap-stage-admin.dtrack.asia'
export const DEMO_VF_HOST = 'demo.dtrack.asia'

export const DTRACK_HOSTS = [
  LOCAL_HOST_P3000,
  LOCAL_HOST,
  DTRACK_HOST,
  STAGE_DTRACK_HOST,
  STAGE_DTRACK_HOST_V2,
  DTRACK_WWW_HOST,
  DTRACK_VN_HOST,
]
export const VIETFISH_HOSTS = [
  LOCAL_HOST_P3001,
  NGROK_HOST,
  VIETFISH_HOST,
  STAGE_VIETFISH_HOST,
  VIETFISH_ONLINE_HOST,
  VIETFISH_COM_VN_HOST,
  STAGE_VIETFISH_ONLINE_HOST,
  DEMO_VF_HOST,
]

export const FOODMAP_HOSTS = [LOCAL_HOST_P3002, STAGE_FOODMAP_HOST]
// Channel status
export const CHANNEL_STATUS_APPROVED = 'approved'

// Service Register Status
export const VIETFISH_SERVICE_REGISTER_STATUSES = ['pending', 'processing', 'approved', 'cancelled']
export const DTRACK_SERVICE_REGISTER_STATUSES_TYPE_1 = [
  'fill_info',
  'advice',
  'contract',
  'paid',
  'profile_completed',
  'submitted_profile',
  'approved_profile',
  'done',
]
export const DTRACK_SERVICE_REGISTER_STATUSES_TYPE_2 = [
  'fill_info',
  'advice',
  'contract',
  'paid',
  'inprogress',
  'done',
]
export const CLIENT_MESSAGE_STATUSES = ['pending', 'processing', 'approved', 'cancelled']

// Export data options
export const EXPORT_ALL_DATA = 'exportAllData'
export const EXPORT_AS_FILTER = 'exportAsFilter'

// Vote
export const VOTE_SALE_CHANNEL_REFERENCE = 'SaleChannelReference'

//service registration steps
export const SERVICE_REGISTRATION_STEPS_TYPE_1 = [
  {
    step: 'register',
    subSteps: [{ value: 'fill_info', index: 0 }],
    image: 'register-step-1',
  },
  {
    step: 'dtrackAdvice',
    subSteps: [{ value: 'advice', index: 1 }],
    image: 'register-step-2',
  },
  {
    step: 'contract/paid',
    subSteps: [
      { value: 'contract', index: 2 },
      { value: 'paid', index: 3 },
    ],
    image: 'register-step-3',
  },
  {
    step: 'prepare',
    subSteps: [{ value: 'profile_completed', index: 4 }],
    image: 'register-step-4',
  },
  {
    step: 'approve',
    subSteps: [
      { value: 'submitted_profile', index: 5 },
      { value: 'approved_profile', index: 6 },
    ],
    image: 'register-step-5',
  },
  // {
  //   step: 'finish',
  //   subSteps: [{ value: 'done', index: 7 }],
  //   image: 'register-step-6',
  // },
]

export const SERVICE_REGISTRATION_STEPS_TYPE_2 = [
  {
    step: 'register',
    subSteps: [{ value: 'fill_info', index: 0 }],
    image: 'register-step-1',
  },
  {
    step: 'dtrackAdvice',
    subSteps: [{ value: 'advice', index: 1 }],
    image: 'register-step-2',
  },
  {
    step: 'contract/paid',
    subSteps: [
      { value: 'contract', index: 2 },
      { value: 'paid', index: 3 },
    ],
    image: 'register-step-3',
  },
  {
    step: 'progress',
    subSteps: [{ value: 'inprogress', index: 4 }],
    image: 'register-step-4',
  },
  // {
  //   step: 'finish',
  //   subSteps: [{ value: 'done', index: 5 }],
  //   image: 'register-step-6',
  // },
]

export const SERVICES_GROUPS = {
  fulfillmentServicesGroup: [
    {
      value: 'esg',
      desc: 'Đang cập nhật',
      altTitle: 'ESG',
      available: true,
      disabled: false,
      type: 2,
    },
    {
      value: 'in-an-bao-bi',
      desc: 'Đang cập nhật',
      altTitle: 'Dịch vụ in ấn',
      available: true,
      disabled: false,
      url: 'https://print.dtrack.asia/gioi-thieu',
      type: 2,
    },
    {
      value: 'dich-vu-fulfillment',
      desc: '<p><span style="font-weight: 400;"><b>Giá tùy theo gói</b></p>',
      altTitle: '<span>Dịch vụ Fulfillment</span>',
      available: true,
      type: 2,
    },
  ],
  marketingServicesGroup: [
    {
      value: 'thiet-ke-logo-bao-bi-san-pham',
      desc: '<p><span style="font-weight: 400;">Chỉ từ</span><br/><b>1.500.000 vnđ</b></p>',
      altTitle: '<span>Thiết kế logo, bao bì<br/>sản phẩm</span>',
      available: true,
      type: 2,
    },
    {
      value: 'lam-tvc-video-gioi-thieu-san-pham',
      desc: 'Giá tùy theo gói',
      altTitle: '<span>Làm TVC, Video<br/>giới thiệu sản phẩm</span>',
      available: true,
      type: 2,
    },
    {
      value: 'dich-vu-chup-anh-san-pham',
      desc: 'Đang cập nhật',
      altTitle: 'Dịch vụ chụp ảnh sản phẩm',
      available: false,
      type: 2,
    },
    {
      value: 'review-san-pham-tang-don-bang-kol',
      desc: 'Đang cập nhật',
      altTitle: 'Review sản phẩm, tăng đơn hàng bằng KOL',
      available: false,
      type: 2,
    },
    {
      value: 'thiet-ke-shop-san-thuong-mai-dien-tu',
      desc: '<p><span style="font-weight: 400;">Chỉ từ</span><br/><b>500.000 vnđ</b></p>',
      altTitle: '<span>Thiết kế sàn<br/>Thương mại điện tử</span>',
      available: false,
      type: 1,
    },
    {
      value: 'ban-hang-livestream',
      desc: '<p>ban-hang-livestream</p>',
      altTitle: '<span>ban-hang-livestream</span>',
      available: false,
      type: 1,
    },
  ],
  salesChannelServicesGroup: [
    {
      value: 'ban-hang-da-kenh-cung-voi-foodmap',
      desc: 'Miễn phí',
      tagImage: '/assets/png/free.png',
      altTitle:
        "<span>Bán hàng đa kênh<br/>trên <span style='color: var(--cl1);'>Foodmap</span></span>",
      available: false,
      type: 2,
    },
    {
      value: 'mo-gian-hang-tren-lazada-tiki-shopee',
      desc: 'Đang cập nhật',
      altTitle:
        "<span>Mở gian hàng trên <span style='color: var(--cl1);'>Lazada / Tiki / Shopee</span></span>",
      available: false,
      type: 2,
    },
    {
      value: 'mo-gian-hang-chinh-hang-tren-alibaba',
      desc: 'Giá tùy theo gói',
      altTitle: "<span>Mở gian hàng<br/><span style='color: var(--cl1);'>Alibaba</span></span>",
      available: false,
      type: 2,
    },
    {
      value: 'ho-tro-vay-von',
      desc: 'Miễn phí',
      tagImage: '/assets/png/free.png',
      altTitle: '<span>Hỗ trợ vốn<br/>kinh doanh</span>',
      available: false,
      type: 1,
    },
    {
      value: 'truy-xuat-nguon-goc-nong-san',
      desc: 'Miễn phí',
      tagImage: '/assets/png/free.png',
      altTitle: '<span>Truy xuất nguồn gốc<br/>nông sản</span>',
      available: false,
      type: 0,
    },
  ],
  legalServicesGroup: [
    {
      value: 'chu-ky-so-doanh-nghiep',
      desc: '<p><span style="font-weight: 400;">Chỉ từ</span><br/><b>932.000 vnđ/gói</b></p>',
      altTitle: '<span>Chữ ký số<br/>doanh nghiệp</span>',
      available: false,
      type: 1,
    },
    {
      value: 'cong-bo-san-pham-thuc-pham',
      desc: '<p><span style="font-weight: 400;">Trọn gói</span><br/><b>3.000.000 vnđ/gói</b></p>',
      altTitle: 'Công bố sản phẩm',
      available: true,
      type: 1,
    },
    {
      value: 'dang-ky-ma-so-ma-vach-gs1',
      desc: '<p><span style="font-weight: 400;">Trọn gói</span><br/<b>2.500.000 vnđ/gói</b></p>',
      altTitle: '<span>Đăng ký mã số<br/>mã vạch GS1</span>',
      available: true,
      type: 1,
    },
    {
      value: 'dang-ky-website-voi-bo-cong-thuong',
      desc: '<p><span style="font-weight: 400;">Trọn gói</span><br/<b>700.000 vnđ/gói</b></p>',
      altTitle: '<span>Đăng ký/thông báo<br/>website với Bộ Công Thương</span>',
      available: true,
      type: 1,
    },
    {
      value: 'dang-ky-nhan-hieu-bao-ho-doc-quyen',
      desc: 'Giá tùy theo gói',
      altTitle: '<span>Đăng ký nhãn hiệu<br/>bảo hộ độc quyền</span>',
      available: false,
      type: 1,
    },
    {
      value: 'hoa-don-dien-tu',
      desc: '<p><span style="font-weight: 400;">Chỉ từ</span><br/><b>1.490.000 vnđ/gói</b></p>',
      altTitle: 'Hóa đơn điện tử',
      available: false,
      type: 1,
    },
  ],
  otherServicesGroup: [
    {
      value: 'kiem-nghiem-chat-luong-san-pham',
      desc: 'Giá tùy theo gói',
      altTitle: '<span>Kiểm nghiệm chất lượng sản phẩm</span>',
      available: true,
      type: 1,
    },
  ],
}

export const FM_SKUS = {
  fmcg: {
    'ăn vặt': '001',
    'đồ uống': '002',
    'gia vị': '003',
    'ngũ cốc': '004',
    trứng: '005',
    sữa: '006',
    'nhập khẩu': '007',
  },
  thịt: {
    'thịt heo': '001',
    'thịt bò': '002',
    'thịt gà': '003',
    'vịt ngan': '004',
    'sản phẩm chế biến': '005',
    'nhập khẩu': '006',
  },
  'thủy hải sản': {
    'hải sản tươi': '001',
    'hải sản đông lạnh': '002',
    'thủy sản tươi': '003',
    'thủy sản sống': '004',
    'thủy sản đông lạnh': '005',
    'nhập khẩu': '005',
    'khô / nắng': '006',
    'sản phẩm chế biến': '007',
    'đặc sản': '008',
  },
  'rau củ trái cây': {
    'rau củ quả đà lạt': '001',
    'rau ăn củ': '002',
    'rau ăn lá': '003',
    'rau ăn quả': '004',
    'trái cây nội địa': '005',
    'trái cây nhập khẩu': '006',
    nấm: '007',
    'rau gia vị': '008',
  },
  eco: {
    'mỹ phẩm': '001',
    'dược phẩm': '002',
    'vật dụng': '003',
    'sống xanh': '004',
  },
  foodmap: {
    'chiến dịch': '001',
    'gây quỹ': '002',
    'dịch vụ': '003',
    khác: '004',
  },
}

export const DTRACK_SALE_CHANNELS = {
  foodmap: 'Foodmap',
  food_map_v2: 'Foodmap v2',
}

export const VF_SALE_CHANNELS = {
  vietfish: 'Vietfish',
}
