const path = require('path')

module.exports = {
  i18n: {
    defaultLocale: 'vi',
    locales: ['en', 'vi'],
  },
  // browserLanguageDetection: false,
  // serverLanguageDetection: false,
  localeDetection: false,
  localePath: path.resolve('./public/static/locales'),
  // react: {
  //   // trigger a rerender when language is changed
  //   bindI18n: 'languageChanged',
  //   // we're NOT using suspsense to detect when the translations have loaded
  //   useSuspense: false,
  //   wait: true,
  //   preload: true,
  // },
}
