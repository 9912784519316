import * as actionTypes from '../../types'

// Upload image
export const uploadAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.UPLOAD,
  payload,
  actionSuccess,
  actionFailure,
})
export const uploadSuccessAction = () => ({
  type: actionTypes.UPLOAD_SUCCESS,
})
export const uploadFailureAction = () => ({
  type: actionTypes.UPLOAD_FAILURE,
})
