import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getProductCategoryFailureAction,
  getProductCategorySuccessAction,
} from '~/redux/actions/ProductCategory'
import { getProductCategoryAPI } from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.GET_PRODUCT_CATEGORY, getProductCategorySaga)
}

function* getProductCategorySaga({ actionSuccess, actionFailure }) {
  try {
    const response = yield call(getProductCategoryAPI)
    const toObj = {}
    response?.data?.categories.map((item) => {
      toObj[item.id] = {
        ...item,
      }
    })
    yield put(getProductCategorySuccessAction(toObj))
    actionSuccess && actionSuccess(toObj)
  } catch (error) {
    yield put(getProductCategoryFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}
