import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  loading: false,
  isRegistering: false,
}

const SalesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SALES_CHANNELS:
      return { ...state, loading: true }
    case actionTypes.GET_SALES_CHANNELS_FAILURE:
      return { ...state, loading: false }
    case actionTypes.GET_SALES_CHANNELS_SUCCESS:
      return { ...state, loading: false, channels: action.payload }
    case actionTypes.REGISTER_SALES_CHANNEL:
      return { ...state, isRegistering: true }
    case actionTypes.REGISTER_SALES_CHANNEL_FAILURE:
      return { ...state, isRegistering: false }
    case actionTypes.REGISTER_SALES_CHANNEL_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updateChannels = state.channels?.map((item) => {
        if (item.id == action.payload) {
          item.register_status = 'pending'
        }
        return item
      })
      return { ...state, isRegistering: false, channels: updateChannels }
    default:
      return state
  }
}

export default SalesReducer
