import { all } from 'redux-saga/effects'
import appSaga from '../sagas/app'
import authSaga from '../sagas/auth'
import clientMessagesSaga from '../sagas/clientMessages'
import oemProducerSaga from '../sagas/oemProducer'
import postsSaga from '../sagas/posts'
import producerSaga from '../sagas/producer'
import productBatchsSaga from '../sagas/productBatchs'
import productCategorySaga from '../sagas/productCategory'
import productsSaga from '../sagas/products'
import productTypesSaga from '../sagas/productTypes'
import purchaseOrdersSaga from '../sagas/purchaseOrders'
import salesSaga from '../sagas/sales'
import servicesApprovalSaga from '../sagas/servicesApproval'
import uploadSaga from '../sagas/upload'
import userSaga from '../sagas/user'

export default function* watchAll() {
  yield all([
    authSaga(),
    appSaga(),
    producerSaga(),
    uploadSaga(),
    productTypesSaga(),
    productsSaga(),
    userSaga(),
    productCategorySaga(),
    salesSaga(),
    postsSaga(),
    productBatchsSaga(),
    oemProducerSaga(),
    purchaseOrdersSaga(),
    servicesApprovalSaga(),
    clientMessagesSaga(),
  ])
}
