import * as actionTypes from '../../types'

// Get product types
export const getProductTypesAction = (actionSuccess, actionFailure) => ({
  type: actionTypes.GET_PRODUCT_TYPES,
  actionSuccess,
  actionFailure,
})
export const getProductTypesSuccessAction = (payload) => ({
  type: actionTypes.GET_PRODUCT_TYPES_SUCCESS,
  payload,
})
export const getProductTypesFailureAction = () => ({
  type: actionTypes.GET_PRODUCT_TYPES_FAILURE,
})
