import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  loading: false,
}

const ProductTypesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_TYPES:
      return { ...state, loading: true }
    case actionTypes.GET_PRODUCT_TYPES_FAILURE:
      return { ...state, loading: false }
    case actionTypes.GET_PRODUCT_TYPES_SUCCESS: {
      let types = action.payload.product_types
      let typeOther = {}
      let position = -1
      types.some((item, index) => {
        if (item.name == 'Khác') {
          typeOther = item
          position = index
          return true
        }
      })
      types.splice(position, 1)
      types.push(typeOther)
      return { ...state, loading: false, data: types }
    }
    default:
      return state
  }
}

export default ProductTypesReducer
