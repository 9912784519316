import * as actionTypes from '../../types'

// Search products
export const searchProductsAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.SEARCH_PRODUCTS,
  payload,
  actionSuccess,
  actionFailure,
})
export const searchProductsSuccessAction = (payload) => ({
  type: actionTypes.SEARCH_PRODUCTS_SUCCESS,
  payload,
})
export const searchProductsFailureAction = () => ({
  type: actionTypes.SEARCH_PRODUCTS_FAILURE,
})

// Get products
export const getProductsAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_PRODUCTS,
  payload,
  actionSuccess,
  actionFailure,
})
export const getProductsSuccessAction = (payload) => ({
  type: actionTypes.GET_PRODUCTS_SUCCESS,
  payload,
})
export const getProductsFailureAction = () => ({
  type: actionTypes.GET_PRODUCTS_FAILURE,
})

// Get product
export const getProductAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_PRODUCT,
  payload,
  actionSuccess,
  actionFailure,
})
export const getProductSuccessAction = (payload) => ({
  type: actionTypes.GET_PRODUCT_SUCCESS,
  payload,
})
export const getProductFailureAction = () => ({
  type: actionTypes.GET_PRODUCT_FAILURE,
})

// Create product
export const createProductAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.CREATE_PRODUCT,
  payload,
  actionSuccess,
  actionFailure,
})
export const createProductSuccessAction = (payload) => ({
  type: actionTypes.CREATE_PRODUCT_SUCCESS,
  payload,
})
export const createProductFailureAction = (payload) => ({
  type: actionTypes.CREATE_PRODUCT_FAILURE,
  payload,
})

// Update product
export const updateProductAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.UPDATE_PRODUCT,
  payload,
  actionSuccess,
  actionFailure,
})
export const updateProductSuccessAction = (payload) => ({
  type: actionTypes.UPDATE_PRODUCT_SUCCESS,
  payload,
})
export const updateProductFailureAction = (payload) => ({
  type: actionTypes.UPDATE_PRODUCT_FAILURE,
  payload,
})

// Delete product
export const deleteProductAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.DELETE_PRODUCT,
  payload,
  actionSuccess,
  actionFailure,
})
export const deleteProductSuccessAction = (payload) => ({
  type: actionTypes.DELETE_PRODUCT_SUCCESS,
  payload,
})
export const deleteProductFailureAction = () => ({
  type: actionTypes.DELETE_PRODUCT_FAILURE,
})

export const clearProductAction = () => ({
  type: actionTypes.CLEAR_PRODUCT,
})
