import { useEffect, useState } from 'react'

function useTraceabilityHost() {
  const [traceabilityHost, setTranceabilityHost] = useState('traceability.dtrack.asia')

  useEffect(() => {
    if (process.env.APP_ENV !== 'production') {
      setTranceabilityHost('stage-web.dtrack.asia')
    }
  }, [])

  return traceabilityHost
}

export default useTraceabilityHost
