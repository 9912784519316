import Head from 'next/head'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { DTRACK, VIETFISH } from '~/utils/constants'

function RenderHeadTitle({ headTitle, headTitleVF, platform }) {
  switch (platform) {
    case DTRACK:
      return (
        <Head>
          <title>{headTitle}</title>
        </Head>
      )
    case VIETFISH:
      return (
        <Head>
          <title>{headTitleVF}</title>
        </Head>
      )
    default:
      return null
  }
}
const mapStateToProps = (state) => ({
  platform: state.layout.platform?.platform,
})

export default compose(connect(mapStateToProps, null))(RenderHeadTitle)
