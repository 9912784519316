/* eslint-disable no-case-declarations */
import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  isLoading: false,
  data: null,
  error: null,
  productToAddBatch: null,
  dataProduct: null,
  productsBatch: null,
  isGettingProductsBatch: false,
  isQuerying: false,
}

const ProductsReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.CREATE_PRODUCT:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.CREATE_PRODUCT_FAILURE:
      return { ...state, isLoading: false, error: payload }
    case actionTypes.CREATE_PRODUCT_SUCCESS:
      return { ...state, isLoading: false, error: null }
    case actionTypes.UPDATE_PRODUCT:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.UPDATE_PRODUCT_FAILURE:
      return { ...state, isLoading: false, error: payload }
    case actionTypes.UPDATE_PRODUCT_SUCCESS:
      if (state.data) {
        let data = state.data
        let pos = -1
        state.data?.some((item, index) => {
          if (item.id == payload.id) {
            pos = index
            return true
          }
        })
        if (pos >= 0) {
          data[pos] = payload
        }
        return { ...state, data: [...data], isLoading: false, error: null }
      }
      return { ...state, isLoading: false }
    case actionTypes.GET_PRODUCT:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_PRODUCT_FAILURE:
      return { ...state, isLoading: false, error: payload }
    case actionTypes.GET_PRODUCT_SUCCESS:
      return { ...state, isLoading: false, error: null, dataProduct: payload }
    case actionTypes.GET_PRODUCTS:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_PRODUCTS_FAILURE:
      return { ...state, isLoading: false, error: payload }
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: [...payload.data.products],
        meta: { ...payload.meta },
        isLoading: false,
        error: null,
      }
    case actionTypes.SEARCH_PRODUCTS:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.SEARCH_PRODUCTS_FAILURE:
      return { ...state, isLoading: false, error: payload }
    case actionTypes.SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: [...payload.data.products],
        meta: { ...payload.meta },
        isLoading: false,
        error: null,
      }
    case actionTypes.SELECT_PRODUCT_TO_ADD_BATCH:
      return {
        ...state,
        productToAddBatch: payload,
      }
    case actionTypes.CLEAR_PRODUCT:
      return {
        ...state,
        dataProduct: null,
      }
    case actionTypes.GET_PRODUCT_BATCHES:
      return {
        ...state,
        isQuerying: payload?.isQuerying,
        isGettingProductsBatch: true,
      }
    case actionTypes.GET_PRODUCT_BATCHES_SUCCESS:
      return {
        ...state,
        productsBatch: [...payload.data],
        productsBatchMeta: payload.meta,
        isGettingProductsBatch: false,
      }
    case actionTypes.GET_PRODUCT_BATCHES_FAILURE:
      return {
        ...state,
        isGettingProductsBatch: false,
      }
    default:
      return state
  }
}

export default ProductsReducer
