import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  loading: false,
  productCategory: {
    data: [],
  },
}

const ProductTypesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_CATEGORY:
      return { ...state, loading: true }
    case actionTypes.GET_PRODUCT_CATEGORY_FAILURE:
      return { ...state, loading: false }
    case actionTypes.GET_PRODUCT_CATEGORY_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    default:
      return state
  }
}

export default ProductTypesReducer
