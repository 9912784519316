import * as actionTypes from '../../types'

// Select product to add batch
export const selectProductToAddBatchAction = (payload) => ({
  type: actionTypes.SELECT_PRODUCT_TO_ADD_BATCH,
  payload,
})

// Get batch
export const getProductBatchAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_PRODUCT_BATCH,
  payload,
  actionSuccess,
  actionFailure,
})
export const getProductBatchSuccessAction = (payload) => ({
  type: actionTypes.GET_PRODUCT_BATCH_SUCCESS,
  payload,
})
export const getProductBatchFailureAction = () => ({
  type: actionTypes.GET_PRODUCT_BATCH_FAILURE,
})

// Get batches
export const getProductBatchesAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_PRODUCT_BATCHES,
  payload,
  actionSuccess,
  actionFailure,
})
export const getProductBatchesSuccessAction = (payload) => ({
  type: actionTypes.GET_PRODUCT_BATCHES_SUCCESS,
  payload,
})
export const getProductBatchesFailureAction = () => ({
  type: actionTypes.GET_PRODUCT_BATCHES_FAILURE,
})

// Add batch
export const createProductBatchAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.CREATE_PRODUCT_BATCH,
  payload,
  actionSuccess,
  actionFailure,
})
export const createProductBatchSuccessAction = (payload) => ({
  type: actionTypes.CREATE_PRODUCT_BATCH_SUCCESS,
  payload,
})
export const createProductBatchFailureAction = (payload) => ({
  type: actionTypes.CREATE_PRODUCT_BATCH_FAILURE,
  payload,
})

// Update batch
export const updateProductBatchAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.UPDATE_PRODUCT_BATCH,
  payload,
  actionSuccess,
  actionFailure,
})
export const updateProductBatchSuccessAction = (payload) => ({
  type: actionTypes.UPDATE_PRODUCT_BATCH_SUCCESS,
  payload,
})
export const updateProductBatchFailureAction = (payload) => ({
  type: actionTypes.UPDATE_PRODUCT_BATCH_FAILURE,
  payload,
})
