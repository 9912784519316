import * as actionTypes from '../../types'

// Get posts
export const resetPostsAction = () => ({
  type: actionTypes.RESET_POSTS,
})

export const getPostsAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_POSTS,
  payload,
  actionSuccess,
  actionFailure,
})
export const getPostsSuccessAction = (payload) => ({
  type: actionTypes.GET_POSTS_SUCCESS,
  payload,
})
export const getPostsFailureAction = () => ({
  type: actionTypes.GET_POSTS_FAILURE,
})

// Get post
export const getPostAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.GET_POST,
  payload,
  actionSuccess,
  actionFailure,
})
export const getPostSuccessAction = (payload) => ({
  type: actionTypes.GET_POST_SUCCESS,
  payload,
})
export const getPostFailureAction = () => ({
  type: actionTypes.GET_POST_FAILURE,
})

// Get post categories
export const getPostCategoriesAction = (actionSuccess, actionFailure) => ({
  type: actionTypes.GET_POST_CATEGORIES,
  actionSuccess,
  actionFailure,
})
export const getPostCategoriesSuccessAction = (payload) => ({
  type: actionTypes.GET_POST_CATEGORIES_SUCCESS,
  payload,
})
export const getPostCategoriesFailureAction = () => ({
  type: actionTypes.GET_POST_CATEGORIES_FAILURE,
})

// Admin
export const adminGetPostsAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.ADMIN_GET_POSTS,
  payload,
  actionSuccess,
  actionFailure,
})
export const adminGetPostsSuccessAction = (payload) => ({
  type: actionTypes.ADMIN_GET_POSTS_SUCCESS,
  payload,
})
export const adminGetPostsFailureAction = () => ({
  type: actionTypes.ADMIN_GET_POSTS_FAILURE,
})

export const adminGetPostAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.ADMIN_GET_POST,
  payload,
  actionSuccess,
  actionFailure,
})
export const adminGetPostSuccessAction = (payload) => ({
  type: actionTypes.ADMIN_GET_POST_SUCCESS,
  payload,
})
export const adminGetPostFailureAction = () => ({
  type: actionTypes.ADMIN_GET_POST_FAILURE,
})

export const adminCreatePostAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.ADMIN_CREATE_POST,
  payload,
  actionSuccess,
  actionFailure,
})
export const adminCreatePostSuccessAction = (payload) => ({
  type: actionTypes.ADMIN_CREATE_POST_SUCCESS,
  payload,
})
export const adminCreatePostFailureAction = () => ({
  type: actionTypes.ADMIN_CREATE_POST_FAILURE,
})

export const adminUpdatePostAction = (payload, actionSuccess, actionFailure) => ({
  type: actionTypes.ADMIN_UPDATE_POST,
  payload,
  actionSuccess,
  actionFailure,
})
export const adminUpdatePostSuccessAction = (payload) => ({
  type: actionTypes.ADMIN_UPDATE_POST_SUCCESS,
  payload,
})
export const adminUpdatePostFailureAction = () => ({
  type: actionTypes.ADMIN_UPDATE_POST_FAILURE,
})
