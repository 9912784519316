import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getProductTypesFailureAction,
  getProductTypesSuccessAction,
} from '~/redux/actions/ProductType'
import { getProductTypesAPI } from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.GET_PRODUCT_TYPES, getProductTypesSaga)
}

function* getProductTypesSaga({ actionSuccess, actionFailure }) {
  try {
    const response = yield call(getProductTypesAPI)
    yield put(getProductTypesSuccessAction(response?.data))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(getProductTypesFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}
