import { useCallback, useEffect } from 'react'
import { compact } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getPostCategoriesAction } from '~/redux/actions/Post'

const usePostCategories = () => {
  const dispatch = useDispatch()
  const postCategories = useSelector((state) => state.posts.categories)

  const fetchPostCategories = useCallback(() => {
    dispatch(getPostCategoriesAction())
  }, [dispatch])

  const findAllParents = (id, categoryIds) => {
    if (!categoryIds) {
      categoryIds = [id]
    }
    const parentId = postCategories?.[id]?.parent_id
    if (parentId == null) {
      categoryIds.push(null)
      return categoryIds.reverse()
    }
    categoryIds.push(parentId)
    return findAllParents(parentId, categoryIds)
  }

  const idToNames = useCallback(
    (id) => {
      if (!postCategories) return null
      const ids = findAllParents(id)
      return compact(ids)
        .map((id) => postCategories[id].name)
        .join(' > ')
    },
    [postCategories],
  )

  const isLastCategory = useCallback(
    (id) => {
      if (!id) return false
      if (!postCategories) return null
      return !Object.keys(postCategories)?.some(
        (cateKey) => postCategories[cateKey].parent_id === id,
      )
    },
    [postCategories],
  )

  return { findAllParents, idToNames, isLastCategory, postCategories, fetchPostCategories }
}

export default usePostCategories
