import { Layout } from 'antd'
import { compose } from 'redux'
import { withTranslation } from '~/i18n'
import FooterBanner from '~/assets/svg/banner_footer.svg'
import Header from './Header'
import CustomSider from './Sider'
// import './styles.scss';

const { Content } = Layout
function DefaultLayout({ children }) {
  return (
    <Layout className="default-layout" style={{ minHeight: '100vh' }}>
      <CustomSider />
      <Layout className="site-layout">
        <Header />
        <Content style={{ padding: '1rem', zIndex: 1 }}>
          {/* <DefaultBreadcrumb /> */}
          {children}
        </Content>
        <FooterBanner className="footer-banner" />
      </Layout>
    </Layout>
  )
}

DefaultLayout.getInitialProps = async () => ({
  namespacesRequired: ['defaultLayout'],
})

export default compose(withTranslation('defaultLayout'))(DefaultLayout)
