import { useEffect } from 'react'

const useDelayEffect = (effect, deps = [], delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay)

    return () => clearTimeout(handler)
  }, [...deps, delay])
}

export default useDelayEffect
