import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getSalesChannelsFailureAction,
  getSalesChannelsSuccessAction,
  registerSalesChannelFailureAction,
  registerSalesChannelSuccessAction,
} from '~/redux/actions/Sales'
import { getSalesChannelsAPI, registerSalesChannelAPI } from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.GET_SALES_CHANNELS, getSalesChannelsSaga)
  yield takeLatest(actionTypes.REGISTER_SALES_CHANNEL, registerSalesChannelSaga)
}

function* getSalesChannelsSaga({ actionSuccess, actionFailure }) {
  try {
    const response = yield call(getSalesChannelsAPI)
    yield put(getSalesChannelsSuccessAction(response?.data?.sale_channels))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(getSalesChannelsFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* registerSalesChannelSaga({ payload, actionSuccess, actionFailure }) {
  try {
    yield call(registerSalesChannelAPI, payload)
    yield put(registerSalesChannelSuccessAction(payload.id))
    actionSuccess && actionSuccess()
  } catch (error) {
    yield put(registerSalesChannelFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}
