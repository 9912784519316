import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getClientMessagesFailureAction,
  getClientMessagesSuccessAction,
  updateClientMessageFailureAction,
  updateClientMessageSuccessAction,
} from '~/redux/actions/ClientMessage'
import { getClientMessagesAPI, updateClientMessageAPI } from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.GET_CLIENT_MESSAGES, getClientMessages)
  yield takeLatest(actionTypes.UPDATE_CLIENT_MESSAGE, updateClientMessage)
}

function* getClientMessages({ payload }) {
  try {
    const response = yield call(getClientMessagesAPI, payload)
    yield put(getClientMessagesSuccessAction(response))
  } catch (error) {
    yield put(getClientMessagesFailureAction(error))
    console.log(error)
  }
}

function* updateClientMessage({ payload }) {
  try {
    const response = yield call(updateClientMessageAPI, payload)
    yield put(updateClientMessageSuccessAction(response))
  } catch (error) {
    yield put(updateClientMessageFailureAction(error))
    console.log(error)
  }
}
