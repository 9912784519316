import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createPurchaseOrderFailureAction,
  createPurchaseOrderSuccessAction,
  getPurchaseOrderFailureAction,
  getPurchaseOrdersFailureAction,
  getPurchaseOrdersSuccessAction,
  getPurchaseOrderSuccessAction,
  updatePurchaseOrderFailureAction,
  updatePurchaseOrderSuccessAction,
} from '~/redux/actions/PurchaseOrder'
import {
  createPurchaseOrderAPI,
  getPurchaseOrderAPI,
  getPurchaseOrdersAPI,
  updatePurchaseOrderAPI,
} from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeLatest(actionTypes.GET_PURCHASE_ORDERS, getPurchaseOrdersSaga)
  yield takeLatest(actionTypes.GET_PURCHASE_ORDER, getPurchaseOrderSaga)
  yield takeLatest(actionTypes.UPDATE_PURCHASE_ORDER, updatePurchaseOrderSaga)
  yield takeLatest(actionTypes.CREATE_PURCHASE_ORDER, createPurchaseOrdersSaga)
}

function* getPurchaseOrdersSaga({ payload }) {
  try {
    const response = yield call(getPurchaseOrdersAPI, payload)
    yield put(getPurchaseOrdersSuccessAction(response))
  } catch (error) {
    yield put(getPurchaseOrdersFailureAction())
    console.log(error)
  }
}

function* getPurchaseOrderSaga({ payload }) {
  try {
    const response = yield call(getPurchaseOrderAPI, payload)
    yield put(getPurchaseOrderSuccessAction(response?.data))
  } catch (e) {
    yield put(getPurchaseOrderFailureAction(e))
    console.log(e)
  }
}

function* createPurchaseOrdersSaga({ payload }) {
  try {
    const response = yield call(createPurchaseOrderAPI, payload)
    yield put(createPurchaseOrderSuccessAction())
  } catch (error) {
    yield put(createPurchaseOrderFailureAction(error))
    console.log(error)
  }
}

function* updatePurchaseOrderSaga({ payload }) {
  try {
    yield call(updatePurchaseOrderAPI, payload)
    yield put(updatePurchaseOrderSuccessAction())
  } catch (error) {
    yield put(updatePurchaseOrderFailureAction(error))
    console.log(error)
  }
}
