import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  isLoading: false,
  posts: [],
  post: {},
}

const ProductTypesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_POST_CATEGORIES:
      return { ...state, isLoading: true }
    case actionTypes.GET_POST_CATEGORIES_FAILURE:
      return { ...state, isLoading: false }
    case actionTypes.GET_POST_CATEGORIES_SUCCESS: {
      return { ...state, isLoading: false, categories: action.payload }
    }
    case actionTypes.RESET_POSTS:
      return { ...state, posts: [] }
    default:
      return state
  }
}

export default ProductTypesReducer
