import withPageContent from 'hoc/withPageContent'
import PropTypes from 'prop-types'
import React from 'react'

function PurchaseOrders({}) {
  return (
    <div style={{ display: 'grid', placeItems: 'center', height: '80vh', width: '100%' }}>
      Bạn không có quyền truy cập
    </div>
  )
}

PurchaseOrders.propTypes = {
  t: PropTypes.func.isRequired,
}

export async function getServerSideProps() {
  return {
    props: {
      requiredRoles: [],
    },
  }
}

export default withPageContent({ translation: 'no-permission' })(PurchaseOrders)
