import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  adminCreatePostFailureAction,
  adminCreatePostSuccessAction,
  adminGetPostFailureAction,
  adminGetPostsFailureAction,
  adminGetPostsSuccessAction,
  adminGetPostSuccessAction,
  adminUpdatePostFailureAction,
  adminUpdatePostSuccessAction,
  getPostCategoriesFailureAction,
  getPostCategoriesSuccessAction,
  getPostFailureAction,
  getPostsFailureAction,
  getPostsSuccessAction,
  getPostSuccessAction,
} from '~/redux/actions/Post'
import {
  adminCreatePostAPI,
  adminGetPostAPI,
  adminGetPostsAPI,
  adminUpdatePostAPI,
  getPostAPI,
  getPostCategoriesAPI,
  getPostsAPI,
} from '~/redux/apis'
import * as actionTypes from '~/redux/types'

export default function* watchAll() {
  yield takeEvery(actionTypes.GET_POSTS, getPostsSaga)
  yield takeLatest(actionTypes.GET_POST, getPostSaga)
  yield takeEvery(actionTypes.ADMIN_GET_POSTS, adminGetPostsSaga)
  yield takeLatest(actionTypes.ADMIN_GET_POST, adminGetPostSaga)
  yield takeLatest(actionTypes.ADMIN_CREATE_POST, adminCreatePostSaga)
  yield takeLatest(actionTypes.ADMIN_UPDATE_POST, adminUpdatePostSaga)
  yield takeLatest(actionTypes.GET_POST_CATEGORIES, getPostCategoriesSaga)
}

function* getPostsSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getPostsAPI, payload)
    yield put(getPostsSuccessAction(response))
    actionSuccess && actionSuccess({ posts: response?.data?.posts, meta: response?.meta })
  } catch (error) {
    yield put(getPostsFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* getPostSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(getPostAPI, payload)
    yield put(getPostSuccessAction(response?.data))
    actionSuccess && actionSuccess(response)
  } catch (error) {
    yield put(getPostFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* getPostCategoriesSaga({ actionSuccess, actionFailure }) {
  try {
    const response = yield call(getPostCategoriesAPI)
    const payload = response?.data?.category_posts.reduce((obj, item) => {
      return { ...obj, [item.id]: item }
    }, {})
    yield put(getPostCategoriesSuccessAction(payload))
    actionSuccess && actionSuccess(response?.data?.category_posts)
  } catch (error) {
    yield put(getPostCategoriesFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* adminGetPostsSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(adminGetPostsAPI, payload)
    yield put(adminGetPostsSuccessAction(response))
    actionSuccess && actionSuccess({ posts: response?.data?.posts, meta: response?.meta })
  } catch (error) {
    yield put(adminGetPostsFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* adminGetPostSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(adminGetPostAPI, payload)
    yield put(adminGetPostSuccessAction(response?.data))
    actionSuccess && actionSuccess(response)
  } catch (error) {
    yield put(adminGetPostFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}

function* adminUpdatePostSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(adminUpdatePostAPI, payload)
    yield put(adminUpdatePostSuccessAction(response?.data))
    actionSuccess && actionSuccess(response)
  } catch (error) {
    yield put(adminUpdatePostFailureAction())
    actionFailure && actionFailure(error?.response?.data?.meta)
    console.log(error?.response?.data?.meta)
  }
}

function* adminCreatePostSaga({ payload, actionSuccess, actionFailure }) {
  try {
    const response = yield call(adminCreatePostAPI, payload)
    yield put(adminCreatePostSuccessAction(response?.data))
    actionSuccess && actionSuccess(response)
  } catch (error) {
    yield put(adminCreatePostFailureAction())
    actionFailure && actionFailure()
    console.log(error)
  }
}
