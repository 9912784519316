import { compact } from 'lodash'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

const useCategories = () => {
  const productCategories = useSelector((state) => state.productCategory?.data)

  const findAllParents = (id, categoryIds) => {
    if (!categoryIds) {
      categoryIds = [id]
    }
    const parentId = productCategories?.[id]?.parent_id
    if (parentId == null) {
      categoryIds.push(null)
      return categoryIds.reverse()
    }
    categoryIds.push(parentId)
    return findAllParents(parentId, categoryIds)
  }

  const idToNames = useCallback(
    (id) => {
      if (!productCategories) return null
      const ids = findAllParents(id)
      return compact(ids)?.map((id) => productCategories?.[id]?.name)
    },
    [productCategories],
  )

  const isLastCategory = useCallback(
    (id) => {
      if (!id) return false
      if (!productCategories) return null
      return !Object.keys(productCategories)?.some(
        (cateKey) => productCategories[cateKey].parent_id === id,
      )
    },
    [productCategories],
  )

  return { findAllParents, idToNames, isLastCategory, productCategories }
}

export default useCategories
