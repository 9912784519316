import * as actionTypes from '../../types'

const DEFAULT_STATE = {
  isLoading: false,
  isExported: false,
  isUpdating: {
    id: null,
    loading: false,
  },
  services: {
    data: [],
    meta: null,
  },
  serviceRequests: {
    data: [],
    meta: {
      total_count: 0,
      current_page: 1,
    },
  },
  error: null,
}

const ProducerReducer = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_SERVICES:
      return {
        ...state,
      }
    case actionTypes.GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: {
          data: payload?.data?.rss || [],
          meta: payload?.meta,
        },
      }
    case actionTypes.GET_SERVICES_FAILURE:
      return {
        ...state,
        error: payload,
      }
    case actionTypes.GET_USER_REGISTER_SERVICES:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_USER_REGISTER_SERVICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        serviceRequests: {
          data: payload?.data?.urss || [],
          meta: payload?.meta,
        },
      }
    case actionTypes.GET_USER_REGISTER_SERVICES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }
    case actionTypes.UPDATE_USER_REGISTER_SERVICE:
      return {
        ...state,
        isUpdating: {
          loading: true,
          id: payload?.id,
        },
      }
    case actionTypes.UPDATE_USER_REGISTER_SERVICE_SUCCESS:
      return {
        ...state,
        isUpdating: {
          loading: false,
          id: null,
        },
        serviceRequests: {
          ...state.serviceRequests,
          data: state.serviceRequests.data.map((i) => {
            if (i.id === payload.id) {
              i.status = payload.status
              i.registration_steps = payload.registration_steps
            }
            return i
          }),
        },
      }
    case actionTypes.UPDATE_USER_REGISTER_SERVICE_FAILURE:
      return {
        ...state,
        isUpdating: {
          loading: false,
          id: null,
        },
        error: null,
      }
    default:
      return state
  }
}

export default ProducerReducer
